import { styled } from "styled-components";

export const Wrapper = styled.div`
  margin: 10px;
  border-radius: 20px;
  width: 98%;
  height: 300px;
  display: flex;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 525px) {
    margin: 0px;
  }
`;

export const WrapperLoading = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`