export const errorMessages: { [key: string]: { [key: string]: string } } = {
  DataRetrieved:{
    en : "Data retrieved" , 
    ru : "Данные получены" , 
    uz : ""
  },
  CardDoesNotBelongToTheCustomer: {
    en: "Card does not belong to the customer",
    ru: "Карта не принадлежит клиенту",
    uz: "Karta mijozga tegishli emas"
  },
  FailedGettingInstallmentData: {
    en: "Failed getting installment data",
    ru: "Не удалось получить данные",
    uz: "Mijoz ma'lumot qabul qilinmadi"
  },
  PhoneNumberIsBeingUsedByAnotherCustomer: {
    en: "Phone number is being used by another customer",
    ru: "Телефонный номер используется другим клиентом",
    uz: "Telefon raqami boshqa mijoz tomonidan ishlatilmoqda"
  },
  CustomerHasUnfinishedCreditProcesses: {
    en: "Customer has unfinished credit processes",
    ru: "Имеется активная заявка",
    uz: "Mijozning faol arizasi mavjud"
  },
  UzcardErrorOccured: {
    en: "Uzcard error occurred",
    ru: "Произошла ошибка Uzcard",
    uz: "Uzcard-da xatolik yuz berdi"
  },
  "Card not found!": {
    en: "Card not found!",
    ru: "Карта не найдена!",
    uz: "Karta topilmadi!"
  },
  NotApproved: {
    en: "Not approved",
    ru: "Не одобрено",
    uz: "Rad etildi"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 59 minutes, 59 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 59 minutes, 59 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 59 минут и 59 секунд",
    uz: "Karta SVGate blokirovka qilindi! 3 soat, 59 daqiqa, 59 soniyada blokdan chiqariladi"
  },
  WaitingForSMSConfirmation: {
    en: "Waiting for SMS confirmation",
    ru: "Ждем SMS подтверждения",
    uz: "SMS tasdiqlashni kutilmoqda"
  },
  "System.NullReferenceException: Object reference not set to an instance of an object.at EleWise.ELMA.Model.Scripts.CA_CardsNewOtp_Scripts.RequestByPost(String jsonRequest, String url, String token) in c:\Windows\Temp\ELMA\CustomActivityScripts_1b1b2d34e60545fd99bf41c8e2d2af55.cs:line 142at EleWise.ELMA.Model.Scripts.CA_CardsNewOtp_Scripts.Execute(CA_CardsNewOtp parameters) in c:\Windows\Temp\ELMA\CustomActivityScripts_1b1b2d34e60545fd99bf41c8e2d2af55.cs:line 64" : {
    en : "Technical error  " ,
    ru : "Техническая ошибка",
    uz :  "Tizimda texnik xato"
  },
  "SMS is not active!": {
    en: "SMS is not active!",
    ru: "SMS-сообщение неактивно!",
    uz: "SMS-xabar faol emas!"
  },
  NoCustomerDataRetrieved: {
    en: "No customer data retrieved",
    ru: "Данные о клиенте не были получены",
    uz: "Mijozlar ma'lumotlari qabul qilinmadi"
  },
  TechnicalErrorOnTheELMASide: {
    en: "Technical error on the ELMA side",
    ru: "Техническая ошибка на стороне ELMA",
    uz: "Tizimda texnik xato Elma tomonidan"
  },
  Scoring: {
    en: "Scoring",
    ru: "Скоринг",
    uz: "Skoring"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 52 minutes, 48 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 52 minutes, 48 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 52 минуты и 48 секунд",
    uz: "Karta SVGate bloklangan! 3 soat, 52 daqiqa, 48 soniyada blokdan chiqariladi"
  },
  "The phone number attached to the card and the requesting phone number do not match!": {
    en: "The phone number attached to the card and the requesting phone number do not match!",
    ru: "Номер телефона, прикрепленный к карте, и запрашиваемый номер телефона не совпадают! (Ошибка Uzcard)",
    uz: "Kartaga biriktirilgan telefon raqami va so'ralgan telefon raqami mos kelmaydi! (Uzcard Xatosi)"
  },
  WaitingUzcardOtpConfirmation: {
    en: "Waiting Uzcard Otp Confirmation",
    ru: "Ждем подтверждения по карте рассрочки",
    uz: "Muddatli to'lov kartasi tasdiqlanishini kutmoqdamiz"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 46 minutes, 52 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 46 minutes, 52 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 46 минут и 52 секунды",
    uz: "Karta SVGate bloklangan! 3 soat, 46 daqiqa, 52 soniyada blokdan chiqariladi"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 46 minutes, 58 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 46 minutes, 58 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 46 минут и 58 секунд",
    uz: "Karta SVGate bloklangan! 3 soat, 46 daqiqa, 58 soniyada blokdan chiqariladi"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 49 minutes, 42 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 49 minutes, 42 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 49 минут и 42 секунды",
    uz: "Karta SVGate bloklangan! 3 soat, 49 daqiqa, 42 soniyada blokdan chiqariladi"
  },
  "Card was blocked in SVGate! Will be unblocked in 3 hours, 45 minutes, 58 seconds": {
    en: "Card was blocked in SVGate! Will be unblocked in 3 hours, 45 minutes, 58 seconds",
    ru: "Карта была заблокирована в SVGate! Будет разблокирована через 3 часа, 45 минут и 58 секунд",
    uz: "Karta SVGate bloklangan! 3 soat, 45 daqiqa, 58 soniyada blokdan chiqariladi"
  },
  InstallmentCardDeniedBankRules: {
    en: "The client is denied an installment card due to the bank's rules.",
    ru: "Клиенту отказано в предоставлении карты рассрочки по правилам банка.",
    uz: "Mijozga bank qoidalariga ko'ra muddatli to'lov kartasini taqdim etishda rad etildi."
  },
  InstallmentCardDeniedInsufficientIncome: {
    en: "The Client is denied an installment card due to insufficient income.",
    ru: "Клиенту отказано в предоставлении карты рассрочки по причине недостаточности доходов.",
    uz: "Mijozga daromad etishmasligi sababli muddatli to'lov kartasini taqdim etishda rad etildi."
  },
  InstallmentCardDeniedScoringCriteria: {
    en: "The client is denied an installment card due to non-compliance with scoring criteria.",
    ru: "Клиенту отказано в предоставлении карты рассрочки по причине не соотвествия критериям скоринга.",
    uz: "Mijozning kartasi skoring mezonlariga mos kelmasligi sababli muddatli to'lov kartasini taqdim etish rad etildi."
  },
  InstallmentCardDeniedCreditHistory: {
    en: "The client is denied an installment card due to non-compliance with credit history.",
    ru: "Клиенту отказано в предоставлении карты рассрочки по причине несоответствия кредитной истории.",
    uz: "Mijozning kredit tarixi mos kelmaganligi sababli muddatli to'lov kartasini taqdim etishda rad etildi."
  },
  InstallmentCardDeniedOutstandingLiabilities: {
    en: "The client is denied an installment card due to outstanding liabilities.",
    ru: "Клиенту отказано в предоставлении карты рассрочки по причине наличия непогашенных обязательств.",
    uz: "Mijozda faol kreditlari borligi sababli muddatli to'lov kartasini taqdim etishda rad etildi."
  },
  failed_during_sending_to_provider: {
    en: "Failed during sending to provider",
    ru: "Ошибка при отправке провайдеру",
    uz: "Providerga yuborishda xatolik yuz berdi"
  },
  failed_during_verify_otp: {
    en: "Failed during verify otp",
    ru: "Ошибка при проверке CМС кода",
    uz: "SMS kodini tekshirishda xatolik yuz berdi"
  },
  failed_during_client_info: {
    en: "Failed during client info",
    ru: "Ошибка при получении информации о клиенте",
    uz: "Mijoz haqida ma'lumot olishda xatolik yuz berdi"
  },
  failed_during_installment_card_info: {
    en: "Failed during installment card info",
    ru: "Ошибка при получении информации о карте рассрочки",
    uz: "Muddatli to'lov kartasi haqida ma'lumot olishda xatolik yuz berdi"
  },
  failed_during_installment_card_status: {
    en: "Failed during installment card status",
    ru: "Ошибка при получении статуса карты рассрочки",
    uz: "Muddatli to'lov kartasi statusini olishda xatolik yuz berdi"
  },
  failed_during_new_client: {
    en: "Failed during new client",
    ru: "Ошибка при регистрации нового клиента",
    uz: "Yangi mijozni ro'yxatdan o'tkazishda xatolik yuz berdi"
  },
  failed_during_scoring: {
    en: "Failed during scoring",
    ru: "Ошибка при скоринге",
    uz: "Skoringda xatolik yuz berdi"
  },
  failed_during_client_decision: {
    en: "Failed during client decision",
    ru: "Ошибка при принятии решения о клиенте",
    uz: "Mijoz haqidagi qarorni qabul qilishda xatolik yuz berdi"
  },
  failed_during_send_contract: {
    en: "Failed during send contract",
    ru: "Ошибка при отправке контракта",
    uz: "Shartnomani yuborishda xatolik yuz berdi"
  },
  failed_during_activate_installment: {
    en: "Failed during activate installment",
    ru: "Ошибка при активации рассрочки",
    uz: "Muddatli to'lovni faollashtirishda xatolik yuz berdi"
  }
};
