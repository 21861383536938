import axios from 'axios';
import { decryptBaseUrl, encryptBaseUrl, generateSecretKey } from './generateSecretKey';

const secretKey = generateSecretKey();
const encryptedBaseUrl = encryptBaseUrl('https://api.allgoodnasiya.uz/application/', secretKey);
const decryptedBaseUrl = decryptBaseUrl(encryptedBaseUrl, secretKey);

export const getApplicationId = async (id: string) => {
  const token = window.localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${decryptedBaseUrl}get/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
