// api.ts

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Lib from 'utils'; 

// Generate a secret key
const secretKey = Lib.generateSecretKey();

// Encrypt the base URL with the secret key
const encryptedBaseUrl = Lib.encryptBaseUrl('https://api.allgoodnasiya.uz/api/merchants/application/', secretKey);

// Decrypt the base URL before making the API request
const decryptedBaseUrl = Lib.decryptBaseUrl(encryptedBaseUrl, secretKey);

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: decryptedBaseUrl }),
  tagTypes: ['ApplicationCreated'],
  endpoints: (builder) => ({
    applicationCreated: builder.mutation({
      query: ({ requestData }) => ({
        url: 'user',
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: requestData,
      }),
      invalidatesTags: ['ApplicationCreated'],
    }),
  }),
});

export const { useApplicationCreatedMutation } = api;
export default api;
