import styled from "styled-components";


export const WrapperLanguage = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    button{
        background-color: transparent;
        border: 1px solid transparent;
        padding: 5px;
        cursor: pointer;
    }
    .active{
        border-bottom: 2px solid gray;
    }
`