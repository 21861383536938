import { Wrapper } from "components/index.s";
import React, { useEffect } from "react";
import { WrapperError } from "./index.s";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewOrder from "components";
type Props = {};

const Finish = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {application_id} = useParams()
  
  useEffect(() => {
    const dataToSend = { application: application_id };
    window.parent.postMessage(dataToSend, "*");
    const handleMessage = (event) => {
      return event.data;
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <NewOrder>
      <>
        <WrapperError>
          <CheckCircleOutlineIcon sx={{ fontSize: "100px", color: "green" }} />
          <h2>{t("Success.0")}</h2>
          <p>{t('Success.2')}</p>
          <button
            onClick={() => {
              window.localStorage.removeItem("card_type");
              navigate("/");
            }}
          >
            {t("Success.1")}
          </button>
        </WrapperError>
      </>
    </NewOrder>
  );
};

export default Finish;
