import styled from "styled-components";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "164px",
  borderRadius: 5,
  marginTop: "32px",
  marginBottom: "32px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#EAEEF2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#F98329",
  },
}));

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 1024px) {
    width: 100%;
    display: inline-block;
    margin: 0 auto;
  }
  button {
    display: flex;
    width: fit-content;
    align-items: center;
    height: fit-content;
    margin-top: auto;
    margin-left: auto;
    background-color: rgb(254 150 50);
    box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
    color: white;
    border: transparent;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 10px;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
      margin: 0 auto;
      margin-top: 20px;
    }
  }
  p {
    color: red;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
`;
export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const BoxValidate = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 30px);
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  label {
    transform: none;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(47, 43, 61, 0.78) !important;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 4px;
  }
`;

export const BoxInput = styled.div`
  border: 1px solid #F6F8FA;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #F6F8FA;
  border-radius: 7px;
  span {
    transform: none;
    line-height: 1.154;
    position: relative;
    margin-bottom: 0.25rem;
    color: rgba(47, 43, 61, 0.78) !important;
    font-family: Arial, Helvetica, sans-serif;
  }
  input {
    outline: transparent;
    transform: none;
    line-height: 1.154;
    font-size: 16px;
    position: relative;
    margin-bottom: 0.25rem;
    color: rgba(47, 43, 61, 0.78) !important;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    border: transparent;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #F6F8FA;
  }
  &:focus-within {
    border: 1px solid rgb(224, 140, 59);
    box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
  }
`;
export const BoxInputCard = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  input {
    border: 1px solid #F6F8FA;
    display: flex;
    align-items: center;
    padding: 13px 10px;
    border-radius: 7px;
    font-size: 16px;
    position: relative;
    color: rgba(47, 43, 61, 0.78) !important;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #F6F8FA;
  }
`;

export const WrapperTop = styled.div`
  display: none;
  @media only screen and (max-width:900px) {
    margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0;
    padding: 0;
    font-family: "Inter" , Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    color: #424a53;
    margin-top: 40px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-family: "Inter" , Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #afb8c1;
    margin-top: 5px;
  }
  }
`;
