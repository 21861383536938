import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { WrapperPassport } from "./Passport.s";
import CachedIcon from "@mui/icons-material/Cached";
import { handlePassportInput } from "utils/passpor-seria";
import { handlePassportPnflInput } from "utils/pnfl-seria";
import { useNavigate, useParams } from "react-router-dom";
import NewOrder from "components";
import { useTranslation } from "react-i18next";
import {
  BorderLinearProgress,
  WrapperTop,
} from "components/card-information/index.s";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const passportRegex = /^[A-Z]{2}\d{7}$/;
const passportRegexPnfl = /^[A-Z0-9]{14}$/;

const calculateAge = (birthDate: Dayjs) => {
  const today = dayjs();
  return today.diff(birthDate, "year");
};

function Passport() {
  const [isPassportNumber, setIsPassportNumber] = useState(true);
  const { application_id } = useParams();
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    passportNumber: Yup.string()
      .matches(
        isPassportNumber ? passportRegex : passportRegexPnfl,
        isPassportNumber ? t("PassportVerify.0") : t("PassportVerify.1")
      )
      .required(
        isPassportNumber ? t("PassportVerify.2") : t("PassportVerify.3")
      ),
    birthDate: Yup.date()
      .nullable()
      .required(t("PassportVerify.4"))
      .test(
        "is-adult",
        t("PassportVerify.11", "Siz 20 yoshga to‘lmagan bo‘lsangiz!"),
        (value) => value && calculateAge(dayjs(value)) >= 20
      ),
  });

  const navigate = useNavigate();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <NewOrder>
        <WrapperTop>
          <h3>Идентификация</h3>
          <h5>Введите свои паспортные данные</h5>
          <BorderLinearProgress variant="determinate" value={100} />
        </WrapperTop>
        <WrapperPassport>
          <Formik
            initialValues={{
              passportNumber: "",
              birthDate: null as Dayjs | null, // Specify the type explicitly
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              // Convert birthDate to YYYY-MM-DD format
              const formattedBirthDate = values.birthDate
                ? values.birthDate.format("YYYY-MM-DD") // Format to 'year-month-day'
                : null;
              await navigate(
                `/identification-face-id?passportNumber=${values.passportNumber}&birthDate=${formattedBirthDate}&application_id=${application_id}`
              );
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div>
                  <label htmlFor="passportField">
                    {isPassportNumber
                      ? t("PassportVerify.5")
                      : t("PassportVerify.6")}
                  </label>
                  {isPassportNumber ? (
                    <Field
                      name={"passportNumber"}
                      type="text"
                      placeholder={"__ _ _ _ _ _ _ _ _"}
                      onChange={(event) =>
                        handlePassportInput(event, setFieldValue)
                      }
                    />
                  ) : (
                    <Field
                      name={"passportNumber"}
                      type="text"
                      placeholder={"_ _ _ _ _ _ _ _ _ _ _ _ _ _"}
                      onChange={(event) =>
                        handlePassportPnflInput(event, setFieldValue)
                      }
                    />
                  )}

                  <ErrorMessage
                    name={"passportNumber"}
                    component="div"
                    className="error"
                  />
                  <span onClick={() => setIsPassportNumber((prev) => !prev)}>
                    <CachedIcon /> {t("PassportVerify.10")}{" "}
                    {isPassportNumber
                      ? t("PassportVerify.6")
                      : t("PassportVerify.7")}
                  </span>
                </div>
                <div>
                  <label htmlFor="birthDate">{t("PassportVerify.8")}</label>
                  <MobileDatePicker
                    value={values.birthDate ? values.birthDate : null}
                    onChange={(date: Dayjs | null) => {
                      setFieldValue("birthDate", date);
                    }}
                    format="DD-MM-YYYY" // Show date in "DD-MM-YYYY" format for the user input
                  />
                  <ErrorMessage
                    name="birthDate"
                    component="div"
                    className="error"
                  />
                </div>
                <button type="submit">{t("PassportVerify.9")}</button>
              </Form>
            )}
          </Formik>
        </WrapperPassport>
      </NewOrder>
    </LocalizationProvider>
  );
}

export default Passport;
