import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.allgoodnasiya.uz/application/' }),
  tagTypes: ['Otp'],
  endpoints: (builder) => ({
    otp: builder.mutation({
      query: ({ requestData, application_id }) => ({
        url: `verify/${application_id}`,
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
        body: requestData,
      }),
      invalidatesTags: ['Otp'],
    }),
  }),
});

export const { useOtpMutation } = api;
export default api;
