import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";

const useWebSocket = (applicationId: string) => {
  const [loadingSocket, setLoadingSocket] = useState(false);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    socketRef.current = io("https://api.allgoodnasiya.uz", {
      transports: ["websocket"],
    });
    const socket = socketRef.current;

    socket.on("connect", () => {
      socket.emit("subscribeToApplication", applicationId);
      setLoadingSocket(true);
    });

    socket.on("update", (update: any) => {
      setLoadingSocket(false);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket");
    });

    return () => {
      socket.disconnect();
    };
  }, [applicationId]);

  return { socket: socketRef.current, loadingSocket };
};

export default useWebSocket;
