import React, { ReactNode } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SmsIcon from "@mui/icons-material/Sms";
import FaceIdIcon from "../assets/icons/face-id.svg";
import FaceIdIconActive from "../assets/icons/face-id-active.svg";
import { ButtonNewOrder, Wrapper, WrapperLeft, WrapperRight } from "./index.s";
import LanguageComponent from "./language-component/languageComponent";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
};

function NewOrder({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { application_id } = useParams();

  const tabRoutes = [
    "/card-info",
    `/sms/${application_id}`,
    `/identification/${application_id}`,
    "/identification-face-id",
    `/otp-bank1/${application_id}`,
    `/otp-bank2/${application_id}`,
  ];
  const activeTab = tabRoutes.indexOf(location.pathname);

  const { t } = useTranslation();
  return (
    <Wrapper>
      <WrapperLeft>
        <button
          style={{
            opacity:
              location.pathname === `/sms/${application_id}` ||
              location.pathname === `/identification/${application_id}` ||
              location.pathname === `/identification-face-id` ||
              location.pathname === `/otp-bank1/${application_id}` ||
              location.pathname === `/otp-bank2/${application_id}`
                ? "0.4"
                : "1",
          }}
          className={activeTab === 0 ? "active" : ""}
        >
          <span className="icon">
            <CreditCardIcon className={activeTab === 0 ? "active" : ""} />
          </span>
          <span>{t("Sidebar.0")}</span>
        </button>
        <button
          style={{
            opacity:
              location.pathname === `/identification/${application_id}` ||
              location.pathname === `/identification-face-id` ||
              location.pathname === `/otp-bank1/${application_id}` ||
              location.pathname === `/otp-bank2/${application_id}`
                ? "0.4"
                : "1",
          }}
          className={activeTab === 1 ? "active" : ""}
        >
          <span className="icon">
            <SmsIcon className={activeTab === 1 ? "active" : ""} />
          </span>
          <span>{t("Sidebar.1")}</span>
        </button>
        <button
          style={{
            opacity:
              location.pathname === `/otp-bank1/${application_id}` ||
              location.pathname === `/otp-bank2/${application_id}`
                ? "0.4"
                : "1",
          }}
          className={activeTab === 2 || activeTab === 3 ? "active" : ""}
        >
          <span className="icon">
            {activeTab === 2 || activeTab === 3 ? (
              <img
                src={FaceIdIcon as unknown as string}
                width={30}
                height={30}
                alt="logo"
              />
            ) : (
              <img
                src={FaceIdIconActive as unknown as string}
                width={30}
                height={30}
                alt="logo"
              />
            )}
          </span>
          <span>{t("Sidebar.2")}</span>
        </button>
        {window.localStorage.getItem("card_type") && (
          <>
            {window.localStorage.getItem("card_type") === "uzcard" && (
              <button
                style={{
                  opacity:
                    location.pathname === `/otp-bank2/${application_id}`
                      ? "0.4"
                      : "1",
                }}
                className={activeTab === 4 ? "active" : ""}
              >
                <span className="icon">
                  <SmsIcon className={activeTab === 4 ? "active" : ""} />
                </span>
                <span>
                  {t("Sidebar.4")} -{" "}
                  {window.localStorage.getItem("card_type") === "uzcard" && "1"}
                </span>
              </button>
            )}
{/* 
            <button className={activeTab === 5 ? "active" : ""}>
              <span className="icon">
                <SmsIcon className={activeTab === 5 ? "active" : ""} />
              </span>
              <span>
                {t("Sidebar.4")} -{" "}
                {window.localStorage.getItem("card_type") === "uzcard"
                  ? "2"
                  : "1"}
              </span>
            </button> */}
          </>
        )}

        <ButtonNewOrder
          onClick={() => {
            window.localStorage.removeItem("card_type");
            navigate("/");
          }}
        >
          {t("Sidebar.3")}
        </ButtonNewOrder>
      </WrapperLeft>
      <WrapperRight>
        <LanguageComponent />
        {children}
      </WrapperRight>
    </Wrapper>
  );
}

export default NewOrder;
