export const handlePassportInput = (event, setFieldValue) => {
  let value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  if (value.length > 2 && value[2] !== '') {
    value = `${value.slice(0, 2)} ${value.slice(2)}`;
  }
  if (value.length > 3) {
    const letters = value.slice(0, 2);
    const rest = value.slice(3).replace(/\D/g, '');
    value = `${letters}${rest}`;
  }
  if (value.length > 9) {
    value = value.slice(0, 9);
  }
  setFieldValue('passportNumber', value);
};
