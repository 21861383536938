import { Wrapper } from 'components/index.s'
import { WrapperError } from './index.s'
import ErrorIcon from "../../assets/icons/error-icon.svg";
import { useTranslation } from 'react-i18next';

type Props = {}

const ErrorServer = (props: Props) => {
  const {t} = useTranslation()
  return (
    <Wrapper>
      <WrapperError>
         <img
            src={ErrorIcon as unknown as string}
            width={200}
            height={200}
            alt="logo"
          />
        <h2>{t("Error.7")}</h2>
        <p>{t("Error.1")} <a href="+998 77 156 44 55">+998 77 156 44 55</a></p>
      </WrapperError>
    </Wrapper>
  )
}

export default ErrorServer